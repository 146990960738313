import { handleErrorWithSentry } from "@sentry/sveltekit";
import * as Sentry from '@sentry/sveltekit';
import { dev } from '$app/environment';

Sentry.init({
  dsn: 'https://c374b2c671d44780991f825ca8aee933@o4505187765518336.ingest.us.sentry.io/4507063774871552',
  environment: `${dev ? "develop" : "production"}`,
});

export const handleError = handleErrorWithSentry();
